import React from "react";

const PrivatePolicy = () => {

  return (
    <div className="">
      <div className="container mx-auto">
      <div>
        <div className="">
          <h1 style={{ textAlign: "center", textDecoration: "underline" }} className="text-4xl font-bold my-12">
            Privacy Policy
          </h1>
        </div>
        <div className="developer-info">
          <h2>1.GENERAL PRINCIPLES </h2>
          <div className="para-text">
            <span className="text">
              <span className="text-heading"></span>
              Bedi Marketing Company("Company", "We," "Us" or "Our") has
              developed for the benefit of the users ("You," "Your" and
              "Yourself"), its Application i.e.: LockIt Up (“Application”) and
              by this privacy policy ("Privacy Policy"), we demonstrate our
              serious commitment towards protecting the privacy of the
              information that we collect through the Application. This privacy
              policy (“Privacy Policy”) specifies the manner in which the
              personally identifying information ("Personal Information") that
              You, by way of express, unambiguous, unconditional and voluntary
              consent, provide Us and/or is collected, received, stored,
              processed, disclosed, transferred, dealt with or otherwise handled
              by Us, and/or that we may automatically collect when You install
              the Application on Your devices, is collected, stored,
              transmitted, shared, etc. This Privacy Policy is deemed to be
              incorporated into and is subject to the Terms of Use.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading"></span>
              If You are accessing, using, or browsing this Application on Your
              own behalf or on behalf of an individual other than Yourself,
              which/who may be either a natural person or an entity other than
              Yourself:
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">a:</span>
              You represent, warrant and covenant that You are above age 18;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">b.</span>
              You represent, warrant and covenant that You are authorized to
              engage in the activities that You conduct within the Application
              on behalf of that person or entity other than Yourself, which by
              way of illustration but not limitation includes the submission and
              receipt of any and all Personal Information on behalf of that
              person or entity other than Yourself;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">c:</span>
              You agree that We can rely on Your representation that You are
              authorized to engage in the activities that You conduct within the
              Application on behalf of that natural person or entity other than
              Yourself; and
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">d:</span>
              You understand that "You," "Your," and "Yourself" in this Privacy
              Policy mean both that natural person or entity and You acting on
              behalf of that natural person or entity other than Yourself.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading"></span>
              This Privacy Policy and the manner and mode as to how the Company
              handles the information that You voluntarily provide when You use
              the Application and/or the information that We may automatically
              collect when You download/ install the Application on your device,
              is governed by the applicable laws of India. The Company makes no
              representations that the Application is appropriate or available
              for use outside the territories of India. Access to the
              Application from territories where its contents or purpose is
              illegal is prohibited. Those who choose to download and/or
              install, and/or use the Application on their devices are
              responsible for compliance with applicable local laws and
              regulations as applicable in the territories where the Application
              is being used. Your use of this Application will be deemed
              conclusively to constitute Your acceptance of and agreement to
              this Privacy Policy and all parts hereof, irrespective of whether
              You register Yourself on the Application or not. The Company
              reserves the right, at its sole discretion, to change this Privacy
              Policy at any time. If We decide to change the Privacy Policy, We
              will post the changes in appropriate places on the Application so
              that You are aware of the changed Privacy Policy. Your continued
              use of the Application after the effective date for any such
              changes to the Privacy Policy will be deemed conclusively to
              constitute Your acceptance of and agreement to the changed Privacy
              Policy; consequently, You should frequently review this Privacy
              Policy.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">a. Profile Information: </span>
              Information which You provide when You download and/or install the
              Application on your device, or information provided otherwise
              while registering on the Application through the Dealers/
              Affiliates/ Third Party Service Providers for a Service availed
              from them, which may include some or all of the following:
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">i.</span>
              Information about Your personal identity such as gender, marital
              status, age, work preferences, photograph, etc.;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">ii.</span>
              Your contact details such as Your physical addresses, postal
              addresses, telephone and fax numbers and the like;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">iii.</span>
              Your profile data includes Information relating to Your skills,
              experience, education level, compensation, geographic region,
              feedback, and other information (“Profile”). If, in any case, you
              believe that an unauthorized Profile has been created about You,
              You can request it to be removed by contacting Us;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">iv.</span>
              Usage Data such as information and trends as to how You use our
              Application, products and services;
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">v:</span>
              Any other information which is otherwise available on any social
              media and is accessible if You choose to sync Your accounts.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">b. Messaging:</span>
              Information shared, transmitted and/or accessible by the Company
              while You communicate with the Company through messages on the
              Application.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">c. Feedback:</span>
              We collect feedback from the Company’s users about their
              experience while dealing with the Company. Please note that any
              feedback you provide via the Application may or may not be made
              publicly viewable via the Application as per the discretion of the
              Company.
            </span>
          </div>
          <span className="text">
            <span className="text-heading">
              d. Payment and Account Information:
            </span>
            Your account history with us including (without limitation) all
            billing information and communications, payment history, order
            history, etc.
          </span>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">e. Log-in information:</span>
              Information such as Your IP address, device type, device language,
              etc.
            </span>
          </div>
          <div className="para-text">
            <span className="text">
              <span className="text-heading">
                f.Transactional Information:{" "}
              </span>
              Transactional Information means information that is contained in
              text messages (that is, SMS) sent by providers of services and/or
              products (including but not limited to financial institutions,
              mobile carriers and utility companies) to your mobile telephone
              number(s) in connection with one or more transactions across
              multiple financial accounts (including purchase, payments, billing
              information and service notifications related thereto). Including
              but not limited to, Transaction Information includes Your:
              <p className="text" style={{ paddingTop: "15px" }}>
                <span className="text-heading">a.</span>
                Mobile phone number
              </p>
            </span>
          </div>
          <p className="text" style={{ paddingTop: "15px" }}>
            <span className="text-heading">b.</span>
            Email address
          </p>
        </div>
        <p className="text" style={{ paddingTop: "15px" }}>
          <span className="text-heading">c.</span>
          Partial payment card data only to the extent such card data is
          disclosed in the said messages
        </p>
      </div>
      <p className="text" style={{ paddingTop: "15px" }}>
        <span className="text-heading">d.</span>
        Information related to the transaction in question includes identity of
        the service or product and the price or fee paid or payable in respect
        thereof.
      </p>
      {/* </p>
      </div> */}
      <div className="para-text">
        <span className="text">
          <span className="text-heading">g.Correspondence Information :</span>
          Content, information about Your correspondents, and the
          destination/origin of communications between You and the Company
          and/or affiliates of the Company, which may include
          emailcommunications,push notifications, alert through Applications, ,
          SMS updates, Telephone Calls, blog, chat room and discussion board
          communications, instant message communicationsexperts forum
          communications, fax-mail communications, membership of mailing lists,
          etc.
        </span>
      </div>
      <div className="para-text">
        <span className="text">
          <span className="text-heading">h. User IDs</span>
          Your usernames, passwords, email addresses, contact details and other
          security-related information used by You in relation to our Services.
        </span>
      </div>
      <div className="para-text">
        <span className="text">
          <span className="text-heading">i. Stored Information:</span>
          Information either created by You or created by Dealers/ Affiliates/
          Third Party Service Providers on the Application, with your consent
          and which You consent to store on our servers such as images, files,
          documents etc. in any format
        </span>
      </div>
      <div className="para-text">
        <span className="text">
          <span className="text-heading">j. Geolocation:</span>
          We may request access or information or permission to track
          location-based information from your device, either continuously or
          while You are using our Application, to provide certain location-based
          services to our various end users.
        </span>
      </div>
      <div className="para-text">
        <span className="text">
          <span className="text-heading">k. Mobile Device Data:</span>
          We automatically collect device information (such as your mobile
          device ID, model, and manufacturer), operating system, version
          information and system configuration information, device and
          application identification numbers, browser type and versions,
          hardware model internet service provide and /or mobile carrier, and
          Internet Protocol (IP) address (or proxy server). If you are using Our
          Application, we may also collect information about the mobile network
          associated with the mobile device, your mobile device’s operating
          system or platform, the type of mobile device you use, your mobile
          device’s unique device ID, and information about the features of
          Application you accessed.
        </span>
      </div>

      <div className="para-text">
        <span className="text">
          <span className="text-heading">Note:</span>
          The Company is not and cannot be held liable/ responsible for the
          activities or privacy policies of any third party with whom Your
          Personal Information is shared. We will only disclose Your Personal
          Information in accordance with this Privacy Policy. If We want to use
          it for any other purpose, We will obtain Your consent. Any of Your
          information which You provide to any Dealers/ Affiliates/ third party
          service provider and/or when You use certain services of the Company
          and is open to public environment or forum including (without
          limitation) any blog, community or discussion board, is not
          confidential and does not constitute Personal Information, and is not
          subject to protection under Privacy Policy. Since such public
          environments are accessible by third parties, it is possible that
          third parties may collect and collate and use such information for
          their own purposes. You should accordingly be careful when deciding to
          share any of Your Personal Information in such public environments.
          Information, which is disclosed publicly, is also shared with our
          affiliates, third party service providers, sponsors of competitions
          etc. unless expressly stated otherwise. The Company shall not be
          liable to You or to any third party for any damages that You or any
          third party may suffer howsoever arising from Your disclosure of
          Personal Information in any public environment. You accordingly
          disclose information in a public environment at Your own risk.
        </span>
      </div>
      {/* </div> */}
      <div className="developer-info">
        <div className="developer-info">
          <h2>2. USE OF INFORMATION </h2>
          <div className="para-text">
            <span className="text">
              We use information collected through the Application and its users
              to provide and improve the services, process Your requests,
              prevent fraud, and provide You with information which as per the
              Company may interest You, comply with the law, and as otherwise
              permitted with Your consent. The Company only collects Your
              Personal Information which has been designated as Personal
              Information or ‘Sensitive Personal Data or Information’ under
              applicable law to conduct our business and to enable us to deliver
              and improve our Services for You. The Company may share Your
              Personal Information:
            </span>
          </div>
          <div className="para-text">
            <p className="text-heading">Cookies: </p>
            <span className="text">
              The Application may use temporary cookies to store certain
              information (that is not sensitive personal data or information)
              that is used by the Company for technical administration of the
              Application, research and development, and for user
              administration. In the course of serving advertisements or
              optimizing services to You, the Company may allow authorized third
              parties to place or recognize a unique cookie on the Your Browser.
              The cookies, however, do not store any of Your Personal
              Information. You may adjust Your device settings to disable
              cookies. If cookies are disabled, You may still be able to use the
              Application, but the Application may be limited in the use of some
              of the features
            </span>
          </div>
          <div className="para-text">
            <p className="text-heading">Google Analytics & Similar Platforms</p>
            <span className="text">
              We may use Google Analytics and similar platforms tracking to
              review Application visitor activities, including Downloads/ views,
              sources and time spent on our Application, etc. The collected
              information is depersonalized and is displayed as numbers, meaning
              it cannot be tracked back to individuals. This will help to
              protect your privacy. Using Google Analytics, we learn how to give
              you a better user experience. Visitors can opt out of Google
              Analytics / similar platforms for Display Advertising and
              customize Google Display Network ads using their Ads Settings.
              Additionally, visitors can opt out of the Google Analytics Browser
              Add-On.
            </span>
          </div>
        </div>
        <div className="developer-info">
          <h2>3. RIGHT TO COLLECT</h2>
          <div className="para-text">
            <span className="text">
              The Company has right to collect the information and the same can
              be done for the following reasons:
            </span>
          </div>
          <h2>a.</h2>
          <div className="para-text">
            <p className="text-heading"></p>
            <span className="text">
              The Company regularly makes improvements to the Application to
              provide its many users, Dealers/ Affiliates/ Third Party Service
              Providers with a more personalized, relevant and intelligent
              service. The collection of Personal Information greatly assists Us
              in this improvement process by identifying the interests and needs
              of the many users, Dealers/ Affiliates/ Third Party Service
              Providers providers of the Application. Collection of Personal
              Information also assists the Company by allowing it to advertise
              its products and/or services in a more efficient manner. Through
              the use of Cookies and on-line data entry, survey, product
              purchase and registration forms, we may monitor, in aggregate and
              individual form, Personal Information. Although our primary goal
              in collecting Personal Information is to deliver a customized and
              personalized service to the users of the Application, we may also
              use Personal Information to send users e-mail and/or Application
              push notification of any information and/or feature which the
              Company feels might be of interest to users of the Application;
            </span>
          </div>
          <h2>b.</h2>
          <div className="para-text">
            <span className="text">
              {" "}
              The Company needs Your identity details, contact details, and
              account history to manage our relationship with You and provide
              services to You in the best manner. We may use this information
              for suggesting best options to You; We collect and store Your
              Service Usage and Transactional Information to:
              <p className="text" style={{ paddingTop: "15px" }}>
                <span className="text-heading">a.</span>
                Determine and verify the charges payable by You and to
                administer our relationship with You;
              </p>
            </span>
            <p className="text" style={{ paddingTop: "15px" }}>
              <span className="text-heading">b.</span>
              Comply with any statutory or regulatory requirement and other
              obligations under the law
            </p>
            {/* </span> */}
            <p className="text" style={{ paddingTop: "15px" }}>
              <span className="text-heading">c.</span>
              Compile statistical and demographical profiles about You for our
              business and marketing activities and to customize our services
              for You. While We are entitled to use such information about You
              for our own internal business purposes without limitation, we will
              only disclose it in an aggregated form which is not capable of
              being used or interpreted in such a manner as to identify You; and
            </p>
            {/* </span> */}
            <p className="text" style={{ paddingTop: "15px" }}>
              <span className="text-heading">d.</span>
              Monitor Your use of our services for the purposes of ensuring
              compliance with our user Agreement
              {/* </span> */}
            </p>
            {/* </span> */}
          </div>
          <div className="para-text">
            <p className="text-heading">Google Analytics & Similar Platforms</p>
            <span className="text">
              We may use Google Analytics and similar platforms tracking to
              review Application visitor activities, including Downloads/ views,
              sources and time spent on our Application, etc. The collected
              information is depersonalized and is displayed as numbers, meaning
              it cannot be tracked back to individuals. This will help to
              protect your privacy. Using Google Analytics, we learn how to give
              you a better user experience. Visitors can opt out of Google
              Analytics / similar platforms for Display Advertising and
              customize Google Display Network ads using their Ads Settings.
              Additionally, visitors can opt out of the Google Analytics Browser
              Add-On.
            </span>
          </div>
        </div>
      </div>
      <div className="developer-info">
        <h2>4. GENERAL EXCEPTIONS</h2>
        <div className="para-text">
          <span className="text">
            If we are required to intercept, disclose, monitor and/or store Your
            Personal Information:
            <p className="text" style={{ paddingTop: "15px" }}>
              <span className="text-heading">a.</span>
              by law;
            </p>
          </span>
          <p className="text" style={{ paddingTop: "15px" }}>
            <span className="text-heading">b.</span>
            to conduct our business and the business of our
            Dealers/Affiliates/Third-party Service Providers;
          </p>
          {/* </span> */}
          <p className="text" style={{ paddingTop: "15px" }}>
            <span className="text-heading">c.</span>
            to secure our systems and the systems of our
            Dealers/Affiliates/Third-party Service Providers; or
            {/* </span> */}
          </p>
          <p className="text" style={{ paddingTop: "15px" }}>
            <span className="text-heading">d.</span>
            to enforce our own rights and the rights of our
            Dealers/Affiliates/Third-party Service Providers, we will do so in
            the manner as prescribed by law Such interception, disclosure,
            monitoring and storage may take place without Your knowledge. In
            that case, we will not be liable to You or any third party for any
            damages howsoever arising from such interception, disclosure,
            monitoring and storage. In order to ensure that all our Users comply
            with the User Agreement/Terms of use, we may monitor Your Personal
            Information to the extent that this may be required to determine
            compliance and/or to identify instances of non-compliance. To ensure
            that the security and integrity of our Services are safeguarded, we
            may monitor Your Personal Information. This monitoring may include
            (without limitation) tracking of ongoing services availed by you
            through the Dealers/Affiliates/Third Party Service Providers, GPS
            Tracking of your location, in case of default on services, etc. We
            may under certain circumstances procure an element of the Services
            from a third-party service provider. To the extent that it may be
            necessary, and solely for the purposes of providing the Service to
            You, You agree that we may disclose to such third party any of Your
            Personal Information that may be necessary for the procurement of
            services from the third party. All the products/ features of the
            Application are built with strong security features that
            continuously protect your information. The insights we gain from
            maintaining our services help us detect and automatically block
            security threats from ever reaching you. And if we do detect
            something risky that we think you should know about, we’ll notify
            you and help guide you through steps to stay better protected.
            {/* </span> */}
          </p>
          {/* </span> */}
        </div>
      </div>
      <div className="developer-info">
        <h2>5. PROMOTIONAL AND INFORMATIONAL MAIL </h2>
        <div className="para-text">
          <span className="text">
            We may send periodic promotional or informational emails to You. You
            may opt out of such communications by following the opt-out
            instructions contained in the email. Please note that it may take up
            to 10 business days for us to process opt-out requests. If You opt
            out of receiving emails about recommendations or other information
            we think may interest You, we may still send You emails about Your
            Account or any Services You have requested or received from our
            Dealer/Affiliate/Third Party Service Provider.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>6. LINKS TO EXTERNAL WEBSITES </h2>
        <div className="para-text">
          <span className="text">
            The Application may contain links to other websites or resources
            over which the Company does not have any control. Such links do not
            constitute an endorsement by the Company of such external websites.
            You acknowledge that the Company is providing these links to You
            only as convenience, and further agree that the Company is not
            responsible for the content of such external websites. We are not
            responsible for the protection and privacy of any information which
            You provide while visiting such external websites and such sites are
            not governed by this Policy. Your use of any external website is
            subject to the terms of use and privacy policy located on the linked
            external website
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>7. SECURITY</h2>
        <div className="para-text">
          <span className="text">
            We employ procedural and technical security measures, which are
            reasonably designed to help protect Your personal information from
            unauthorized access or disclosure. The Company uses end-to-end
            encryption, passwords, and physical security measures to help
            protect Your personal information against unauthorized access and
            disclosure. No security measures, however, are 100% complete.
            Therefore, We do not promise and cannot guarantee, and thus You
            should not expect, that Your personal information or private
            communications cannot be collected and used by others. You should
            take steps to protect Yourselfagainst unauthorized access to Your
            password and device by, among other things, trying to hinder the
            firewall of the Application through any Third Party Service
            Provider. We are not responsible for the unauthorized use of Your
            information or for any activity on Your Account via unauthorized
            activity. Policies.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>8. USE OF INFORMATION </h2>
        <div className="para-text">
          <span className="text">
            You are responsible for maintaining the accuracy of the information
            provided by You. We will retain Your information for as long as Your
            account is active with any services and/or until the deletion of
            Your Account after the completion of the active services availed by
            you through this Application or otherwise. We shall not retain such
            information for longer than is required for the purposes for which
            the information may lawfully be used or is otherwise required under
            any other law for the time being in force. After a period of time,
            Your data may be anonymized and aggregated, and then may be held by
            us as long as necessary for us to provide our Services effectively,
            but our use of the anonymized data will be solely for analytic
            purposes.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>9. WITHDRAWAL OF CONSENT </h2>
        <div className="para-text">
          <span className="text">
            You may choose to withdraw Your consent provided herein at any point
            in time. Such withdrawal of consent must be sent in writing to
            info@lockitup.in. In case You do not provide Your consent or later
            withdraw Your consent, We may request you not to access the
            Application and/or use the Services. In such a scenario, the Company
            may delete Your information (Personal or otherwise) or de-identify
            it so that it is anonymous and not attributable to You.
            Notwithstanding anything contained herein, the Company may retain
            some or all of Your information in accordance with our policies
            and/or any applicable law(s). We reserve the right to registration
            and to the services provided through the Application.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>10. YOUR CHOICES AND RIGHTS </h2>
        <div className="para-text">
          <span className="text">
            You have certain choices regarding how we may communicate with you.
            Registered users may update their choices regarding the types of
            communications they receive from us through their account. You may
            also opt-out of receiving marketing emails from us by following the
            opt-out instructions provided in those emails. Please note that We
            reserve the right to send you certain communications relating to
            your account or use of the service (for example, administrative and
            service announcements) via email and other means and these
            transactional account messages may be unaffected if you opt-out from
            receiving marketing communications. You may opt-out of receiving
            text messages as well. Users who download/install and access the
            Application may, with permission, receive push notifications.
            Notification preferences may be modified in the settings of the
            device on which the Application is installed.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>11. CHANGE IN PRIVACY POLICY </h2>
        <div className="para-text">
          <span className="text">
            The Company may update this Privacy Policy at any time with or
            without advance notice. Such changes may be reflected by displaying
            a notice on the Application or sending emails to all users/account
            holdersYour continuous use of the Application and/or its services
            would be deemed as your consent to the updated Privacy Policy and
            Your Personal Information will be dealt with by the Company in a
            manner which shall be consistent with the Privacy Policy in effect
            at the time.
          </span>
        </div>
      </div>
      <div className="developer-info">
        <h2>12. CONSENT TO PRIVACY POLICY </h2>
        <div className="para-text">
          <span className="text">
            You acknowledge that this Privacy Policy is a part of the Terms of
            Use and You unconditionally agree that by becoming a user of the
            Application or by downloading and installing the Application, you
            signify Your (i) assent to this Privacy Policy, and (ii) consent to
            the Company for using, collecting, processing and/or disclosing Your
            Personal Information in the manner and for the purposes set out in
            this Privacy Policy. Your download, installation and usage of the
            Application is subject to this Privacy Policy and the Terms of Use.
          </span>
        </div>
      </div>
      {/* </div> */}
      </div>
    </div>
  );
};

export default PrivatePolicy;
